import React, { useEffect, useMemo } from 'react';
import {
  IconButton,
  ListItemIcon,
  MenuItem,
  styled,
  Tooltip,
} from '@mui/material';
import { StarOutlineRounded, StarRounded } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import useSnackbar from '../hooks/useSnackbar';
import { useAppSelector } from '../redux/store';
import {
  useCreatePreferencesMutation,
  useGetPreferencesQuery,
  usePatchPreferencesMutation,
} from '../redux/services/preferences/api';

interface Props {
  asListItem?: boolean;
  className?: string;
  id?: string;
}

const FavBtn = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiSvgIcon-root': {
    fontSize: '1.75rem',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.text.secondary
        : theme.palette.secondary.main,
  },
}));

const QuickAccessButton = (props: Props) => {
  const { className, asListItem, id: tid } = props;
  const location = useLocation();
  const { showSuccess, showForbiddenError } = useSnackbar();
  const { data: preferences, isError } = useGetPreferencesQuery();
  const isEdgeEnv = useAppSelector((state) => state.environment === 'edge');

  const [
    updatePreferences,
    { isSuccess: isUpdatePreferencesSuccess, error: updatePreferencesError },
  ] = usePatchPreferencesMutation();
  const [
    createPreference,
    { isSuccess: isCreatePreferencesSuccess, error: createPreferencesError },
  ] = useCreatePreferencesMutation();

  const path = useMemo(() => {
    const lastPathValue = location.pathname.split('/').at(-1);
    const ifProjectPage =
      lastPathValue === 'overview' ||
      lastPathValue === 'software' ||
      lastPathValue === 'systems';

    return ifProjectPage
      ? location.pathname.split('/').slice(0, -1).join('/')
      : location.pathname;
  }, [location.pathname]);

  const isExists = preferences?.preferences?.quickAccess
    ? preferences?.preferences?.quickAccess?.findIndex(
        (_id) => _id === path,
      ) !== -1
    : false;

  const handleModify = (currentPreferences) => {
    if (isError || !currentPreferences) {
      createPreference({ quickAccess: [path] });
      return;
    }

    updatePreferences({
      quickAccess: isExists
        ? currentPreferences.quickAccess.filter((item) => item !== path)
        : [...(currentPreferences.quickAccess || []), path],
    });
  };

  const handleSetFav = () => {
    handleModify(preferences?.preferences);
  };

  useEffect(() => {
    if (!isUpdatePreferencesSuccess) return;
    showSuccess(
      isExists ? 'Removed from quick access' : 'Added to quick access',
    );
  }, [isUpdatePreferencesSuccess]);
  useEffect(() => {
    if (!isCreatePreferencesSuccess) return;
    showSuccess('Added to quick access');
  }, [isCreatePreferencesSuccess]);

  useEffect(() => {
    showForbiddenError({
      error: updatePreferencesError,
      customForbiddenMessage:
        "You don't have permission to save to quick access",
      customDefaultMessage:
        'Failed to update quick access. Something went wrong',
    });
  }, [updatePreferencesError]);
  useEffect(() => {
    showForbiddenError({
      error: createPreferencesError,
      customForbiddenMessage:
        "You don't have permission to save to quick access",
      customDefaultMessage:
        'Failed to update quick access. Something went wrong',
    });
  }, [createPreferencesError]);

  if (isEdgeEnv) return null;

  return (
    <>
      {asListItem ? (
        <MenuItem id={tid} onClick={handleSetFav} className={className}>
          <ListItemIcon>
            {isExists ? (
              <StarRounded sx={{ mr: 1, ml: '-2px' }} />
            ) : (
              <StarOutlineRounded sx={{ mr: 1, ml: '-2px' }} />
            )}
          </ListItemIcon>
          {isExists ? 'Remove from quick access' : 'Add to quick access'}
        </MenuItem>
      ) : (
        <Tooltip
          title={isExists ? 'Remove from quick access' : 'Add to quick access'}
          PopperProps={{
            container: () => document.querySelector('.fullscreen'),
          }}
        >
          <FavBtn className={className} onClick={handleSetFav} id={tid}>
            {isExists ? <StarRounded /> : <StarOutlineRounded />}
          </FavBtn>
        </Tooltip>
      )}
    </>
  );
};

QuickAccessButton.defaultProps = {
  className: '',
  id: '',
  asListItem: false,
};

export default QuickAccessButton;
